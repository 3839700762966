<!--
   [2024/08/27 sb.hwang]
   StarXrAdminServiceDetailItem.vue
   @note 서비스 관리 서비스 정보 컴포넌트
-->
<template>
    <v-container fluid tabindex="0" class="pa-0" @keyup.enter="fullScreen">
        <v-snackbar
            v-model="showSnackbar"
            class="fullscreen-snackbar"
            :timeout="3000"
        >
            <div class="font-white font-medium star-xr-h6">
                {{ $t("fullScreenInstruction") }}
            </div>
        </v-snackbar>
        <div class="star-xr-admin-service-detail-item-title-wrap">
            <div class="star-xr-admin-service-detail-item-title">
                <div class="detail-item-title font-semi-bold">
                    {{ item.srvcTtl }}
                </div>
                <div class="font-orange font-bold star-xr-h5">
                    {{ $t(srvcCategory[item.srvcCtgrySn]) }}
                </div>
            </div>
            <v-menu bottom offset-y>
                <template v-slot:activator="{ on, attrs }">
                    <v-btn
                        icon
                        v-bind="attrs"
                        v-on="on"
                        class="star-xr-community-detail-answer-mode"
                    >
                        <v-icon size="22"> mdi-dots-horizontal </v-icon>
                    </v-btn>
                </template>

                <v-list>
                    <v-list-item @click="clickToEdit">
                        <v-img
                            :src="require(`@/assets/images/icon-edit.svg`)"
                            contain
                            width="16"
                            height="16"
                        />
                        <v-list-item-title class="font-gray8 font-medium">
                            {{ $t("edit") }}
                        </v-list-item-title>
                    </v-list-item>
                    <v-list-item @click="clickToDelete">
                        <v-img
                            :src="
                                require(`@/assets/images/icon-delete-outline.svg`)
                            "
                            contain
                            width="16"
                            height="16"
                        />
                        <v-list-item-title class="font-red font-medium">
                            {{ $t("delete") }}
                        </v-list-item-title>
                    </v-list-item>
                </v-list>
            </v-menu>
        </div>

        <unity-vue
            v-if="initComplete"
            :unity="unityContext"
            class="service-detail-webgl"
            :style="{ display: isUnityLoading ? 'none' : 'block' }"
        />
        <div v-if="isUnityLoading" class="service-detail-webgl-loading">
            <v-card class="text-center">
                <v-card-text>
                    <v-progress-circular
                        indeterminate
                        color="#FF6D2F"
                        class="my-5"
                    />
                    <div class="star-xr-h5 font-semi-bold font-gray7">
                        {{ loadingProgress }}{{ $t(loadingMessage) }}
                    </div>
                </v-card-text>
            </v-card>
        </div>
        <div class="star-xr-admin-service-detail-item-info-wrap">
            <div class="star-xr-h4 font-medium font-gray7">
                {{ item.companyNm + " | " + item.uploadDt }}
            </div>
            <div class="badge-wrap">
                <star-xr-badge
                    v-for="(tagItem, i) in item.tag"
                    :key="i"
                    :text="tagItem"
                />
            </div>
        </div>
        <div
            class="star-xr-admin-service-detail-item-desc star-xr-h5 font-gray8"
        >
            {{ item.srvcCn }}
        </div>
        <alert-dialog
            v-model="openDialog"
            :title="dialogTitle"
            :content="dialogContent"
            title-style="font-red"
            agree-style="star-xr-btn-red-outlined"
            @agree="agreeToDelete"
        />
    </v-container>
</template>

<script>
import UnityWebgl from "unity-webgl";
import UnityVue from "unity-webgl/vue";
import StarXrBadge from "@/components/com/StarXrBadge.vue";
import AlertDialog from "@/components/com/AlertDialog.vue";
import { srvcCategory, unityLangCd } from "@/commons/resources";
import { storageServerUrl } from "@/config";
import { openDB } from "idb";
import JSZip from "jszip"

export default {
    name: "StarXrAdminServiceDetailItem",
    props: {
        fileDown: {
            type: Boolean,
        },
    },
    components: {
        UnityVue,
        StarXrBadge,
        AlertDialog,
    },
    computed: {
        item() {
            return this.$store.state.service;
        },
        webglFile() {
            return this.$store.getters.getServiceWebglFileUrl;
        },
        token() {
            return this.$store.state.user.token;
        },
        langCd() {
            return this.$store.state.user.translation;
        },
        streamingAssetsBlobs: {
            get() {
                return this.item.streamingAssetsBlobs;
            },
            set(value) {
                this.$store.commit("setStreamingAssetsBlobs", value);
            },
        },
    },
    data() {
        return {
            openDialog: false,
            dialogTitle: "",
            dialogContent: "",
            unityContext: null,
            srvcCategory,
            likes: false,
            showSnackbar: false,
            loadingProgress: 0,
            isUnityLoading: true,
            initComplete: false,
            downloadComplete: false,
            isSetUnity: false,
            loadingMessage: "fileDownloadProgress",
            timer: null,
            url: {},
        };
    },
    methods: {
        /**
         * @method clickToEdit
         * @note 서비스 편집 화면으로 전환하는 함수
         * @email sb.hwang@naviworks.com
         */
        clickToEdit() {
            this.$router.push({
                name: "starXrAdminServiceEdit",
                params: { idx: this.item.srvcSn },
            });
        },
        /**
         * @method clickToDelete
         * @note 서비스 정보 삭제alert 띄우는 함수
         * @email sb.hwang@naviworks.com
         */
        clickToDelete() {
            this.openDialog = true;
            this.dialogTitle = "deleteService";
            this.dialogContent = "deleteServiceConfirmation";
        },
        /**
         * @method agreeToDelete
         * @note 서비스를 삭제하는 함수
         * @email sb.hwang@naviworks.com
         */
        agreeToDelete() {
            this.$store
                .dispatch("deleteAdminService", this.item.srvcSn)
                .then(() => {
                    this.$router
                        .push({ name: "starXrAdminService" })
                        .catch(() => {});
                })
                .catch((err) => {
                    console.log(err);
                });
        },
        /**
         * @method setUnity
         * @note Unity WebGL 세팅하는 함수
         * @email sb.hwang@naviworks.com
         */
         async setUnity() {
            if(this.isSetUnity) {
                return;
            } else {
                this.isSetUnity = true;
            }
            const zipUrl = storageServerUrl + this.webglFile.webglUploadFileUrl;

            this.timer = setInterval(() => {
                if (this.loadingProgress >= 100) {
                    this.downloadComplete = true;
                    this.loadingProgress = "";
                    this.loadingMessage = "extractingZipFile";
                }
                if (!this.downloadComplete) {
                    this.loadingProgress += 1;
                }
            }, 120);

            const zipResponse = await fetch(zipUrl);
            const zipBlob = await zipResponse.blob();
            const zip = await JSZip.loadAsync(zipBlob);
            const files = zip.files;

            const loaderFileName = Object.keys(files).find((name) =>
                name.match(/Build\/.*\.loader\.js$/)
            );
            const dataFileName = Object.keys(files).find((name) =>
                name.match(/Build\/.*\.data$/)
            );
            const frameworkFileName = Object.keys(files).find((name) =>
                name.match(/Build\/.*\.framework\.js$/)
            );
            const codeFileName = Object.keys(files).find((name) =>
                name.match(/Build\/.*\.wasm$/)
            );

            this.url.loaderUrl = URL.createObjectURL(
                new Blob([await zip.file(loaderFileName).async("blob")], {
                    type: "application/javascript",
                })
            );
            this.url.dataUrl = URL.createObjectURL(
                new Blob([await zip.file(dataFileName).async("blob")], {
                    type: "application/octet-stream",
                })
            );
            this.url.frameworkUrl = URL.createObjectURL(
                new Blob([await zip.file(frameworkFileName).async("blob")], {
                    type: "application/javascript",
                })
            );
            this.url.codeUrl = URL.createObjectURL(
                new Blob([await zip.file(codeFileName).async("blob")], {
                    type: "application/wasm",
                })
            );

            this.streamingAssetsBlobs = {};
            let tempAssets = {};
            for (const fileName of Object.keys(files)) {
                if (
                    !files[fileName].dir &&
                    fileName.startsWith("StreamingAssets/")
                ) {
                    const fileBlob = await zip.file(fileName).async("blob");
                    const mimeType = this.getMimeType(fileName); // 파일 확장자를 기반으로 MIME 타입 결정
                    tempAssets[fileName] = URL.createObjectURL(
                        new Blob([fileBlob], { type: mimeType })
                    );
                }
            }
            this.streamingAssetsBlobs = tempAssets;
            for (let [key, value] of Object.entries(
                this.streamingAssetsBlobs
            )) {
                await this.saveToIndexedDB(key, value);
            }

            this.unityContext = new UnityWebgl({
                loaderUrl: this.url.loaderUrl,
                dataUrl: this.url.dataUrl,
                frameworkUrl: this.url.frameworkUrl,
                codeUrl: this.url.codeUrl,
                streamingAssetsUrl: "StreamingAssets",
            });

            this.initComplete = true;

            clearInterval(this.timer);
            this.loadingProgress = 0;
            this.loadingMessage = "loadingUnityEngine";

            this.unityContext.on("progress", (unityInstance) => {
                this.loadingProgress = Math.floor((unityInstance / 0.88) * 100);
                if (this.loadingProgress >= 100) {
                    this.loadingProgress = 100;
                }
            });

            this.unityContext.on("mounted", () => {
                this.loadingProgress = 100;
                this.isUnityLoading = false;
                this.resToken();
                this.resLangCd();
            });

            console.log("Unity setup complete");
        },
        async saveToIndexedDB(key, value) {
            const db = await openDB("StreamingAssetsDB", 1, {
                upgrade(db) {
                    if (!db.objectStoreNames.contains("assets")) {
                        db.createObjectStore("assets");
                    }
                },
            });
            const tx = db.transaction("assets", "readwrite");
            await tx.store.put(value, key);
            await tx.done;
            console.log(`Data saved to IndexedDB with key: ${key}`);
        },
        /**
         * @method resToken
         * @note Unity에 토큰 전달하는 함수
         * @email gy.yang@naviworks.com
         */
        async resToken() {
            // Unity 양방향 통신 send('게임 오프젝트 이름', '메서드 이름', '보낼 데이터')
            // 게임 오프젝트 이름: 유니티 씬 내의 게임 오브젝트 이름
            // 메서드 이름: 해당 게임 오브젝트에 붙어있는 스크립트의 메서트 이름
            // 보낼 데이터: 전달하려는 데이터(하나의 인자만 보낼 수 있음 리스트&배열 전달 불가능)
            this.unityContext.send("TokenManager", "GetToken", this.token);
        },
        /**
         * @method resLangCd
         * @note Unity에 토큰 전달하는 함수
         * @email gy.yang@naviworks.com
         */
         async resLangCd() {
            // Unity 양방향 통신 send('게임 오프젝트 이름', '메서드 이름', '보낼 데이터')
            // 게임 오프젝트 이름: 유니티 씬 내의 게임 오브젝트 이름
            // 메서드 이름: 해당 게임 오브젝트에 붙어있는 스크립트의 메서트 이름
            // 보낼 데이터: 전달하려는 데이터(하나의 인자만 보낼 수 있음 리스트&배열 전달 불가능)
            if(!this.isUnityLoading) {
                this.unityContext.send(
                    "LocalizationManager",
                    "GetLangCode",
                    unityLangCd[this.langCd]
                );
            }
        },
        /**
         * @method fullScreen
         * @note WebGL 전체화면 함수
         * @email gy.yang@naviworks.com
         */
        fullScreen() {
            this.unityContext.setFullscreen(true);
        },
        getMimeType(fileName) {
            const extension = fileName.split(".").pop().toLowerCase();
            const mimeTypes = {
                png: "image/png",
                jpg: "image/jpeg",
                jpeg: "image/jpeg",
                gif: "image/gif",
                svg: "image/svg+xml",
                json: "application/json",
                txt: "text/plain",
                html: "text/html",
                css: "text/css",
                js: "application/javascript",
                mp3: "audio/mpeg",
                mp4: "video/mp4",
                pdf: "application/pdf",
                zip: "application/zip",
                xml: "application/xml",
                // 필요한 MIME 타입을 추가
            };

            return mimeTypes[extension] || "application/octet-stream"; // 기본값으로 `application/octet-stream`
        },
        async cleanupUnityResources() {
            try {
                // Unity 컨텍스트 클리어 및 언로드
                if (this.unityContext) {
                    if (this.unityContext.Module) {
                        if (this.unityContext.Module.Timers) {
                            for (let timer of this.unityContext.Module.Timers) {
                                clearTimeout(timer);
                                clearInterval(timer);
                            }
                        }
                    }
                }

                if (this.unityContext && this.unityContext.Module) {
                    if (this.unityContext.Module.asm) {
                        this.unityContext.Module.asm = null;
                    }
                    this.unityContext.Module = null;
                }

                const unityCanvas = document.getElementById('unityCanvas');
                if (unityCanvas) {
                    unityCanvas.remove(); // DOM에서 제거
                }

                const canvas = document.getElementById('unityCanvas');
                if (canvas) {
                    const gl = canvas.getContext('webgl') || canvas.getContext('experimental-webgl');
                    if (gl) {
                        gl.getExtension('WEBGL_lose_context')?.loseContext();
                    }
                }

                if (window.unityInstance) {
                    window.unityInstance = null; // Unity 메시지 큐 제거
                }

                this.unityContext.clear();
                await this.unityContext.unload();

                if (this.unityInstance) {
                    this.unityInstance.Quit(() => {
                        console.log("Unity instance quit.");
                        this.unityInstance = null;
                    });
                }
                
                // Object URL 해제
                URL.revokeObjectURL(this.url.loaderUrl);
                URL.revokeObjectURL(this.url.dataUrl);
                URL.revokeObjectURL(this.url.frameworkUrl);
                URL.revokeObjectURL(this.url.codeUrl);

                for (let value of Object.values(this.url.streamingAssetsBlobs)) {
                    URL.revokeObjectURL(value);
                }

                // 비동기 작업들을 Promise로 처리
                const unityCachePromise = new Promise((resolve, reject) => {
                    const unityCache = indexedDB.open("UnityCache", 4);

                    unityCache.onsuccess = (event) => {
                        const db = event.target.result;
                        if (db.objectStoreNames.contains("RequestMetaDataStore")) {
                            const objectStore = db
                                .transaction(["RequestMetaDataStore"], "readwrite")
                                .objectStore("RequestMetaDataStore");
                            const deleteRequest = objectStore.clear();
                            deleteRequest.onsuccess = () => {
                                console.log("deleted UnityCache");
                                resolve();
                            };
                            deleteRequest.onerror = (e) => reject(e);
                        } else {
                            resolve();
                        }
                    };

                    unityCache.onerror = (event) => {
                        console.error("Error opening IndexedDB:", event);
                        reject(event);
                    };
                });

                const idbfsCachePromise = new Promise((resolve, reject) => {
                    const idbfsCache = indexedDB.open("/idbfs", 21);

                    idbfsCache.onsuccess = (event) => {
                        const db = event.target.result;
                        if (db.objectStoreNames.contains("FILE_DATA")) {
                            const objectStore = db
                                .transaction(["FILE_DATA"], "readwrite")
                                .objectStore("FILE_DATA");
                            const deleteRequest = objectStore.clear();
                            deleteRequest.onsuccess = () => {
                                console.log("deleted idbfsCache");
                                resolve();
                            };
                            deleteRequest.onerror = (e) => reject(e);
                        } else {
                            resolve();
                        }
                    };

                    idbfsCache.onerror = (event) => {
                        console.error("Error opening IndexedDB:", event);
                        reject(event);
                    };
                });

                const cacheStoragePromise = caches.open("UnityCache_Unity_Unity").then((cache) => {
                    const url = "/starxr/upload/" + this.webglFile.webglUploadFileUrl;
                    return cache.delete(url).then((deleted) => {
                        if (deleted) {
                            console.log("deleted cache");
                        } else {
                            console.log("cache not found");
                        }
                    });
                }).catch((err) => {
                    console.error("Error accessing cache:", err);
                });

                // 모든 비동기 작업이 완료될 때까지 대기
                await Promise.all([unityCachePromise, idbfsCachePromise, cacheStoragePromise]);

                indexedDB.deleteDatabase("UnityCache");
                indexedDB.deleteDatabase("/idbfs");
                caches.delete("UnityCache_Unity_Unity");

                // Unity 컨텍스트 해제
                this.unityContext = null;
                this.loadingProgress = 0;
                this.isUnityLoading = true;
                this.initComplete = false;
                this.downloadComplete = false;
                this.loadingMessage = "fileDownloadProgress";
                this.isSetUnity = false;

                console.log("Unity resources cleaned up successfully.");
            } catch (error) {
                console.error("Error during Unity resource cleanup:", error);
                throw error;
            }
        },
    },
    async beforeRouteLeave(to, from, next) {
        try {
            await this.cleanupUnityResources();
            next();
        } catch {
            next(false); // 오류 발생 시 라우터 이동 취소
        }
    },
    async beforeRouteUpdate(to, from, next) {
        try {
            await this.cleanupUnityResources();
            next();
        } catch {
            next(false); // 오류 발생 시 라우터 이동 취소
        }
    },
    created() {
        this.showSnackbar = true;
    },
    watch: {
        fileDown() {
            if(this.fileDown) {
                this.setUnity();
            }
        },
        langCd() {
            this.resLangCd();
        }
    }
};
</script>

<style scoped>
.star-xr-admin-service-detail-item-title-wrap {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 14px;
}

.fullscreen-snackbar::v-deep .v-snack__wrapper {
    top: -620px;
    left: 120px;
    border-radius: 4px;
    background-color: rgba(0, 0, 0, 0.7);
    box-shadow: none;
}

.star-xr-admin-service-detail-item-title {
    display: flex;
    align-items: center;
    gap: 14px;
}

.detail-item-title {
    font-size: 24px;
}

.v-list {
    background-color: #f5f5f5;
}

.v-list-item {
    gap: 3px;
}

.v-list-item__title {
    font-size: 14px;
}

.canvas-container {
    width: 100%;
    overflow: hidden;
    margin-bottom: 30px;
    border-radius: 5px;
}

.service-detail-webgl {
    width: 100%;
    min-height: 530px;
    background-color: #ececec;
    margin-bottom: 10px;
    border-radius: 10px;
}

.service-detail-webgl-loading {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    min-height: 710px;
    background-color: #ececec;
    margin-bottom: 10px;
    border-radius: 10px;
}

.star-xr-admin-service-detail-item-info-wrap {
    display: flex;
    justify-content: space-between;
    align-items: center;

    margin-bottom: 14px;
}

.badge-wrap {
    display: flex;
    gap: 6px;
}

.star-xr-admin-service-detail-item-desc {
    padding: 14px;
    background-color: #fafafa;
    border-radius: 5px;
}

.v-card {
    background-color: transparent !important;
    box-shadow: none !important;
}

.v-progress-circular,
.v-progress-circular::v-deep .v-progress-circular__overlay {
    color: #707070 !important;
    caret-color: #707070 !important;
}
</style>
